import 'core-js/stable';
import 'react-app-polyfill/ie11';
import './Sass/base.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import config from './Config';

if (window.location.host.includes('homepage') && window.location.hash === '#downloadRedirect') {
  if (navigator.userAgent.match(/Android/i)) window.location.replace(config.reactApp.CVC_ANDROID);
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) window.location.replace(config.reactApp.CVC_IOS);
}
if (window.location.host.includes('homepage') && window.location.hash === '#safetyPlusDownloadRedirect') {
  if (navigator.userAgent.match(/Android/i)) window.location.replace(config.reactApp.SAFETYPLUS_ANDROID);
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) window.location.replace(config.reactApp.SAFETYPLUS_IOS);
}

window.renderForwoodHomepageMicroFrontend = (containerId, history, context) => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App history={history} context={context} />
      </Provider>
    </I18nextProvider>,
    document.getElementById(containerId)
  );
};

window.unmountForwoodHomepageMicroFrontend = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

registerServiceWorker();
